export const GetCurrentDateTimeStamp = () => {
  var now = new Date();
  return `${now.toLocaleDateString()} - ${now.toLocaleTimeString()}`;
};

export const GetDateTimePartsFor = (verificationTimeStamp) => {
  return verificationTimeStamp.split("-");
};

export const FromHtmlFormatConvert = (dateString) => {
  const dateParts = dateString.split("-");

  return `${dateParts[2]}/${dateParts[1]}/${dateParts[0]}`;
};
