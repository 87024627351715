import React from "react";
import * as queryString from "query-string";
import Layout from "../components/Layout";
import BioScanLandingLogo from "../svgs/biosxanlogolanding .svg";
import firebase from "gatsby-plugin-firebase";
import { useObject } from "react-firebase-hooks/database";
import VerifiedTickSVG from "../svgs/validationtick.svg";
import UnverifiedCrossSVG from "../svgs/validationcross.svg";
import { FromHtmlFormatConvert } from "../Services/DateTimeService";

/**
 * Generates a component that accepts a url parameter (x) used to query a request from a QR code.
 */
export default ({ location }) => {
  if (typeof window !== "undefined") {
    const { x } = queryString.parse(location.search);
    const uid = x;
    const [value, error] = useObject(firebase.database().ref(`VaccinationRecords/${uid}`));

    return (
      <Layout className="container mx-auto font-body">
        {error && <div>{error.message}</div>}
        <div className="flex items-center h-screen justify-items-center">
          <div className="flex flex-wrap w-full overflow-hidden">
            <div className="w-full overflow-hidden">
              <BioScanLandingLogo className="block w-full h-48 py-6" />
            </div>

            <div className="flex w-10/12 py-6 mx-auto overflow-hidden text-lg font-medium text-center text-green-600 bg-white rounded-md shadow-md justify-items-center">
              <div className="w-full">
                {!value && <p>Loading ...</p>}
                {value && (value.val().Vaccine2Verified === "true" || value.val().Vaccine1Verified === "true") && (
                  <div className="text-green-600 ">
                    <VerifiedTickSVG className="inline-block h-full mr-2 -mt-1" />
                    <h1 className="text-2xl">Verified</h1>
                    <p className="text-gray-600">This Biosxan is verified.</p>
                    <p className="px-6 py-2 text-sm text-gray-600">
                      {value.val().Vaccine1Verified === "true" && value.val().Vaccine2Verified === "true"
                        ? `Both vaccine's have been administered & verified.`
                        : `The first vaccine has been administered & verified.`}
                    </p>
                    <p className="pt-2 text-sm text-green-600">{`Vaccine 1 Verified On ${FromHtmlFormatConvert(value.val().Vaccine1Date)}`}</p>
                    <p className={`pb-2 text-sm text-green-600 ${value && value.val().Vaccine2Verified === "true" ? `block` : `hidden`}`}>{`Vaccine 2 Verified On ${FromHtmlFormatConvert(
                      value.val().Vaccine2Date
                    )}`}</p>
                    <p className={`pb-2 text-sm text-green-600 ${value && value.val().Vaccine2Verified === "true" ? `hidden` : `block`}`}>
                      Vaccine 2 <span className="text-red-DEFAULT">Not Recorded</span>
                    </p>

                    <p className="px-6 py-4 text-xs text-gray-500">Further details can be provided by the verified user via their Biosxan app.</p>
                  </div>
                )}
                {value && value.val().Vaccine1Verified !== "true" && value.val().Vaccine2Verified !== "true" && (
                  <div className=" text-red-DEFAULT">
                    <UnverifiedCrossSVG className="inline-block h-full mr-2 -mt-1" />
                    <h1 className="text-2xl">Unverified</h1>
                    <p className="text-gray-600">This Biosxan is not verified.</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  } else {
    return <></>;
  }
};
